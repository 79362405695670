import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import io from 'socket.io-client';
// import Peer from 'simple-peer';
// import Peer from 'simple-peer/simplepeer.min.js';
import Peer, { MediaConnection } from 'peerjs';
import { VolumeOff, VolumeUp } from '@mui/icons-material';

const socket = io('wss://api.appcleanin.it', { transports: ['polling'] });


const CentraleOperativa = forwardRef((props, ref) => {
  const operatorsData = [
    { id: 2, status: 'offline', streaming: false, peer: null, streamSettings: { muted: true } }, // operator1
    { id: 3, status: 'offline', streaming: false, peer: null, streamSettings: { muted: true } }, // operator2
    { id: 4, status: 'offline', streaming: false, peer: null, streamSettings: { muted: true } }, // operator3
    { id: 5, status: 'offline', streaming: false, peer: null, streamSettings: { muted: true } }, // operator4
  ];

  const [operators, setOperators] = useState(operatorsData);
  const [messages, setMessages] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const [adminPeer, setAdminPeer] = useState(null);
  const [streamInitializer, setStreamInitializer] = useState(undefined); // Holds the peerId of Operator that receives a stream from admin
  const [adminID, peerIdPrefix, peerIdSuffix] = [1, `abdelilahelhajouji_`, `_marcosantullo`];

  useEffect(() => {
    // Update operators online status
    listenForStatusChanges()

    initializeStreamHandler();

    socket.on('streamStopped', (operatorId) => {
      stopStream(operatorId);
    });

    socket.on('message', (message) => {
      setMessages(prev => [...prev, message]);
    });

    socket.on('intervention', (intervention) => {
      setInterventions(prev => [...prev, intervention]);
    });

    return () => {
      socket.off('operatorStatus');
      socket.off('streamStarted');
      socket.off('streamSignal');
      socket.off('streamSignalReturn');
      socket.off('streamStopped');
      socket.off('message');
      socket.off('intervention');
    };
  }, [operators]);

  const listenForStatusChanges = () => {
    socket.on('operatorStatus', ({ id, status }) => {
      setOperators(prev => prev.map(op => op.id === id ? { ...op, status } : op));
    });
  }

  useImperativeHandle(ref, () => ({
    stopActiveStream: () => { operators.forEach(op => stopStream(op.id)) },
  }));

  const initializeStreamHandler = () => {
    const localPeerId = `${peerIdPrefix}${adminID}${peerIdSuffix}`;
    const peer = new Peer(localPeerId);
    peer.on('open', (id) => {
      setAdminPeer(peer);

      peer.on('call', (call) => {
        // Check whether to answer or not
        const streamerId = call.peer.replace(peerIdPrefix, "").replace(peerIdSuffix, "");

        navigator.mediaDevices.getUserMedia({ video: false, audio: true })
          .then(localStream => {

            call.answer(localStream);

            const videoEl = document.getElementById(`video-stream-${streamerId}`);
            videoEl.poster = '/ongoing-call.png'

            call.on('close', () => {
              stopStream(streamerId);
            });

            call.on('error', (err) => {
              stopStream(streamerId);
            });

            call.on("stream", (remoteStream) => {
              setOperators((prev) => prev.map(item => item.id == streamerId ? { ...item, streaming: true } : item))
              if (videoEl) {
                videoEl.srcObject = remoteStream;
              }
            })
          })
          .catch(error => {
            console.log('Cannot access camera', error);
            alert('Cannot access camera ' + error);
          })
      });
    })
  }

  const toggleStream = (operatorId) => {
    const operator = operators.find(op => op.id === operatorId);
    if ((operator.peer && !operator.peer.destroyed) || (streamInitializer == adminID && adminPeer && !adminPeer.destroyed) || (operator && operator.streaming)) {
      stopStream(operatorId);
      socket.emit('stopStream', operatorId);
    } else {
      // startStream(operatorId);
      socket.emit('startStream', parseInt(operatorId));
    }
  };

  const sendMessage = (operatorId, message) => {
    socket.emit('message', { from: 'Centrale', to: operatorId, text: message });
  };


  const stopStream = (operatorId) => {
    if (streamInitializer == adminID) {
      // This means admin wants to stop his streaming which supposed to only be shared with one operator
      // as a result re-initializing the component's state will do exactly the job
    }

    const operator = operators.find(op => op.id == operatorId);
    if (!operator || (!operator.streaming)) return;

    const videoEl = document.getElementById(`video-stream-${operatorId}`);
    const mediaStream = videoEl?.srcObject;
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        mediaStream.removeTrack(track);
      });
      videoEl.srcObject = null;
      videoEl.poster = null;
    }

    const connId = `${peerIdPrefix}${operatorId}${peerIdSuffix}`;

    if (adminPeer.connections && Object.keys(adminPeer.connections).includes(connId)) {
      const conn = adminPeer.connections[connId][0];
      if (conn) conn.close();
    }

    setOperators((prev) => prev.map(item => item.id != operatorId ? item : { ...item, peer: null, streaming: false, streamSettings: { muted: true } }));

    if (streamInitializer == adminID) setStreamInitializer(null);
  }

  return (
    <div>
      <h2>Centrale Operativa</h2>
      <div className="operator-grid">
        {operators.map(op => (
          <div key={op.id} className="operator-window">
            <h3>Operatore {parseInt(op.id) - 1}</h3>
            <p>
              Stato: <span className={op.status == 'online' ? 'text-green' : ''}>{op.status}</span>
            </p>
            <div className='relative'>
              <video
                id={`video-stream-${op.id}`}
                // ref={el => videoRefs.current[op.id - 2] = el}
                autoPlay={true}
                muted={op.streamSettings.muted}
                playsInline={true}
              />
              <div className='bottom-right'>
                {
                  <span onClick={() => { op.streaming && setOperators((prev) => prev.map(o => o.id !== op.id ? o : { ...o, streamSettings: { ...o.streamSettings, muted: !o.streamSettings.muted } })) }}>
                    {
                      op.streamSettings.muted
                        ? <VolumeOff className='stream-icon' />
                        : <VolumeUp className='stream-icon text-red' />
                    }
                  </span>
                }
              </div>
            </div>
            <button onClick={() => toggleStream(op.id)} className={op.streaming ? 'bg-red' : ''}>
              {op.streaming ? `Stop Stream` : `Start Stream`}
            </button>
            <input
              type="text"
              placeholder="Invia messaggio"
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  sendMessage(op.id, e.target.value);
                  e.target.value = '';
                }
              }}
            />
          </div>
        ))}
      </div>
      <div className="messages">
        <h3>Messaggi</h3>
        {messages.map((msg, index) => (
          <p key={index}>{msg.from} a {msg.to}: {msg.text}</p>
        ))}
      </div>
      <div className="interventions">
        <h3>Interventi</h3>
        {interventions.map((int, index) => (
          <p key={index}>Operatore {parseInt(int.operatorId) - 1}: {int.text}</p>
        ))}
      </div>
    </div>
  );
})

export default CentraleOperativa;