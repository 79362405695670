import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import Peer from 'simple-peer';
import './App.css';
import InterfacciaOperatore from './components/Operator';
import CentraleOperativa from './components/Central';

const socket = io('wss://api.appcleanin.it', { transports: ['polling'] });

function Login({ setToken, setRole, setUserId }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Attempting login with:', username, password);
      const response = await axios.post('https://api.appcleanin.it/login', { username, password });
      console.log('Login response:', response.data);
      setToken(response.data.token);
      setRole(response.data.role);
      setUserId(response.data.id);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('role', response.data.role);
      localStorage.setItem('userId', response.data.id);
      socket.emit('login', { token: response.data.token });
    } catch (error) {
      console.error('Login error:', error.response ? error.response.data : error.message);
      alert('Login fallito: ' + (error.response ? error.response.data.message : error.message));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <button type="submit">Login</button>
    </form>
  );
}

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [role, setRole] = useState(localStorage.getItem('role'));
  const [userId, setUserId] = useState(localStorage.getItem('userId'));

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);
      if (userId && parseInt(userId) !== 1) {
        socket.emit('updateOperatorStatus', { id: parseInt(userId), status: 'online' });
      }
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
    }
  }, [token, role]);

  useEffect(() => {
    socket.on('notification', (message) => {
      alert(message);  // In una versione più avanzata, potresti usare una libreria di notifiche più sofisticata
    });

    return () => {
      socket.off('notification');
    };
  }, []);

  const stopActiveStream = useRef();
  const handleLogout = () => {
    // Stop if there's any active stream
    stopActiveStream.current.stopActiveStream()

    // Make operator offile
    socket.emit('operatorStatus', { id: userId, status: 'offline' });

    // Process loggout
    setToken(null);
    setRole(null);
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('userId');
  };

  if (!token) {
    return <Login setToken={setToken} setRole={setRole} setUserId={setUserId} />;
  }

  return (
    <div className="container">
      <button onClick={handleLogout}>Logout</button>
      {role === 'admin' ? <CentraleOperativa ref={stopActiveStream} /> : <InterfacciaOperatore ref={stopActiveStream} operatorId={userId} />}
    </div>
  );
}

export default App;