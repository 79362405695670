const openCam = async (videoDevices = [], selectedCam) => {
    let constraints = {
        video: true,
        audio: true,
    };
    if (videoDevices.length) {
        const selectedCameraDevice = videoDevices[selectedCam];
        // Use deviceId to select camera
        constraints = {
            audio: true,
            video: { deviceId: { exact: selectedCameraDevice.deviceId } },
        }
    }

    return navigator.mediaDevices.getUserMedia(constraints)
        .then(stream => {
            return Promise.resolve(stream)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export { openCam };