import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import io from 'socket.io-client';
// import Peer from 'simple-peer';
// import Peer from 'simple-peer/simplepeer.min.js';
import Peer from 'peerjs';
import { VolumeOff, VolumeUp, Cameraswitch } from '@mui/icons-material';
import { openCam } from '../helpers';

const socket = io('wss://api.appcleanin.it', { transports: ['polling'] });

const InterfacciaOperatore = forwardRef((props, ref) => {
    const { operatorId } = props;

    const [streaming, setStreaming] = useState(false);
    const [message, setMessage] = useState('');
    const [peer, setPeer] = useState(null);
    const videoRef = useRef();
    const [isMute, setIsMute] = useState(true);
    const [selectedCam, setSelectedCam] = useState(0);
    const [videoDevices, setVideoDevices] = useState([]);
    const [adminID, peerIdPrefix, peerIdSuffix] = [1, `abdelilahelhajouji_`, `_marcosantullo`];
    const toggleStreamRef = useRef(null);
    const [caller, setCaller] = useState(null);
    const [adminIsBusyWith, setAdminIsBusyWith] = useState(null);

    useEffect(() => {
        socket.on('streamStopped', (streamerId) => {
            if (operatorId == streamerId) {
                stopStream();
            } else if (adminIsBusyWith) {
                setAdminIsBusyWith(null);
            }

        });
        socket.on('streamStarted', (streamerId) => {
            if (operatorId == streamerId) {
                setCaller(adminID)
                setTimeout(() => {
                    toggleStreamRef.current.click();
                    // startStream();
                }, 200);
            } else {
                setAdminIsBusyWith(streamerId);
            }
        })

        getVideoDevices(); // Fetch video devices when the component mounts
    }, [peer, streaming, adminIsBusyWith]);

    const toggleStream = () => {
        if (streaming) {
            stopStream();
            socket.emit('stopStream', operatorId);
        } else {
            startStream(0);
        }
    };

    // Get the list of all video input devices
    const getVideoDevices = async () => {
        if (!('mediaDevices' in navigator && navigator.mediaDevices.enumerateDevices)) {
            alert('Your browser doesn\'t support this feature')
            return
        }

        const devices = await navigator.mediaDevices.enumerateDevices();
        const _videoDevices = devices.filter(device => device.kind === 'videoinput');
        setVideoDevices(_videoDevices); // Store all video devices (front/back cameras)
    };

    const toggleCam = () => {
        if (!peer || peer.destroyed || !videoDevices.length) return;


        const videoEl = videoRef.current;
        videoEl.poster = 'ongoing-call.png';
        if (videoEl) {
            const mediaStream = videoEl.srcObject;
            mediaStream.getVideoTracks().forEach(t => t.stop())
        }

        const newCamIndex = selectedCam === 0 ? 1 : 0;
        setSelectedCam(newCamIndex);
        startStream(newCamIndex);
    }

    const startStream = (camIndex = 0) => {
        if (camIndex === undefined && peer && !peer.destroyed) peer.destroy(); // Destroy the peer listener instance first

        if (!videoDevices.length) return;

        openCam(videoDevices, camIndex)
            .then(stream => {
                const localPeerId = `${peerIdPrefix}${operatorId}${peerIdSuffix}`;
                const centralPeerId = `${peerIdPrefix}${adminID}${peerIdSuffix}`;

                // Check if the stream is already started (toggle between cameras)
                if ((!peer || peer.destroyed) || !streaming) {
                    // Display the local video stream

                    // Create a new peer connection
                    const newPeer = new Peer(localPeerId);
                    newPeer.on('open', (id) => {

                        const call = newPeer.call(centralPeerId, stream);
                        const videoEl = videoRef.current;
                        videoEl.poster = '/ongoing-call.png';

                        setStreaming(true);
                        call.on("stream", (remoteStream) => {
                            if (videoEl) {
                                const newStream = new MediaStream();
                                stream.getVideoTracks().forEach(t => newStream.addTrack(t));

                                if (true /* replace `true` by ` caller == adminID` in order to only allow operator hears the admin when the admin is the caller */) {
                                    remoteStream.getAudioTracks().forEach(t => newStream.addTrack(t));
                                };
                                videoEl.srcObject = newStream;
                            }
                        });

                        call.on('close', () => {
                            stopStream();
                        })
                        call.on('error', () => {
                            stopStream();
                        })

                        setPeer(newPeer);
                    })
                } else {

                    // Switch camera
                    const switchCam = (stream) => {
                        const videoEl = videoRef.current;
                        videoEl.poster = '/ongoing-call.png';

                        const centralPeerId = `${peerIdPrefix}${adminID}${peerIdSuffix}`;
                        const conn = peer.connections[centralPeerId][0];
                        conn.localStream.getVideoTracks().forEach(t => { t.stop(); conn.localStream.removeTrack(t) });
                        conn.localStream.addTrack(stream.getVideoTracks()[0])
                        const call = peer.call(centralPeerId, conn.localStream);
                        call.on('stream', (remoteStream) => {
                            const newStream = new MediaStream();
                            stream.getVideoTracks().forEach(t => newStream.addTrack(t));

                            if (caller == adminID) {
                                remoteStream.getAudioTracks().forEach(t => newStream.addTrack(t));
                            };
                            videoEl.srcObject = newStream;
                        })
                    }
                    switchCam(stream)
                    //

                }
            })
            .catch(err => {
                console.error('Error accessing media devices.', err)
                alert('error accessing camera ' + err)
            });
    };

    const sendMessage = () => {
        if (message.trim()) {
            socket.emit('message', { from: `Operatore ${parseInt(operatorId) - 1}`, text: message });
            setMessage('');
        }
    };

    const stopStream = () => {
        (videoRef.current) && (videoRef.current.srcObject = null);
        try {
            // Reload works perfectly to reinitialize the component
            setTimeout(() => {
                window.location.reload();
            }, 200);
        } catch (error) {
            console.warn('error while stopping stream', error)
        }
    }

    useImperativeHandle(ref, () => ({
        stopActiveStream: () => stopStream()
    }))

    const registerIntervention = () => {
        socket.emit('intervention', { operatorId, text: 'Nuovo intervento registrato' });
    };

    return (
        <div>
            <h2>Interfaccia Operatore {operatorId - 1}</h2>
            <div style={{ width: '80%', height: '80%' }} className='relative'>
                <video
                    ref={videoRef}
                    autoPlay={true}
                    muted={isMute}
                    playsInline={true}
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
                <div className='bottom-right'>
                    <span className='' onClick={() => setIsMute((prev) => (!prev))}>
                        {
                            streaming && (
                                isMute
                                    ? <VolumeOff className='stream-icon' />
                                    : <VolumeUp className='stream-icon text-red' />
                            )
                        }
                    </span>
                </div>
            </div>
            {
                adminIsBusyWith
                    ? <p>Admin on another call</p>
                    : <button
                        className={streaming ? 'bg-red' : ''}
                        ref={toggleStreamRef} onClick={toggleStream}
                    >  {streaming ? 'Stop Stream' : 'Start Stream'}</button>

            }
            {
                videoDevices.length > 0 && streaming
                    ? <button onClick={() => toggleCam()} >{selectedCam === 0 ? 'Use back cam' : 'Use front cam'}</button>
                    : ''
            }
            <div>
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Scrivi un messaggio..."
                />
                <button onClick={sendMessage}>Invia</button>
            </div>
            <p id="debug">Debugger</p>
            <button onClick={registerIntervention}>Registra Intervento</button>
        </div>
    );
})

export default InterfacciaOperatore;